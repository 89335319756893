export const colours = {
  red: "#c71e5a",
  orange: "#cb7817",
  yellow: "#b8af5b",
  green: "#84b523",
  cyan: "#80c0b7",
  blue: "#50adbf",
  violet: "#8b66cd",
  magenta: "#cb4bc1",

  white: "#f8f8f3",
  grey: "#75715e",
  black: "#272822",

  redLight: "#fa2772",
  redLighter: "#ffa8c7",
  redDark: "#7d1339",
  orangeLight: "#fd9720",
  orangeLighter: "#ffd29e",
  orangeDark: "#7d4b0f",
  yellowLight: "#e6db74",
  yellowLighter: "#e6db74",
  yellowDark: "#736d3a",
  greenLight: "#a6e22e",
  greenLighter: "#d5f791",
  greenDark: "#547217",
  cyanLight: "#a1efe4",
  cyanLighter: "#a1efe4",
  cyanDark: "#507772",
  blueLight: "#66d9ef",
  blueLighter: "#bdecf5",
  blueDark: "#346b76",
  magentaLight: "#fd5ff0",
  magentaLighter: "#ffc8fa",
  magentaDark: "#7e2f78",
  violetLight: "#ae81ff",
  violetLighter: "#dccafb",
  violetDark: "#574080",
  greyLight: "#b9b7ae",
  greyLighter: "#dcdbd6",
  greyDark: "#3e3d33",
  whiteDark: "#c6c6c1",
  blackDark: "#161713",
};
